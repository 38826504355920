import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { animalIconsMap } from './AnimalIcon.constants';

const AnimalIcon = forwardRef(({ animal, ...props }, ref) => {
    const Icon = animalIconsMap[animal];

    if (!Icon) {
        throw new TypeError(`Invalid AnimalIcon: ${animal}`);
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Icon data-testid={`animal-icon-${animal}`} {...props} ref={ref} />;
});

AnimalIcon.propTypes = {
    animal: PropTypes.oneOf(Object.keys(animalIconsMap)).isRequired,
};

export default AnimalIcon;
