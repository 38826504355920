import {
    IconBird,
    IconCat,
    IconDog,
    IconFarm,
    IconFish,
    IconHorse,
    IconReptile,
    IconPoultry,
    IconSmallPet,
    IconMedication,
} from 'components/Icon';

const animalIconsMap = {
    birds: IconBird,
    bird: IconBird,
    cats: IconCat,
    cat: IconCat,
    kittens: IconCat,
    seniorCats: IconCat,
    dogs: IconDog,
    dog: IconDog,
    puppies: IconDog,
    seniorDogs: IconDog,
    farmAnimals: IconFarm,
    'farm-animals': IconFarm,
    'farm animal': IconFarm,
    sheep: IconFarm,
    fish: IconFish,
    horses: IconHorse,
    horse: IconHorse,
    reptiles: IconReptile,
    reptile: IconReptile,
    lizard: IconReptile,
    poultry: IconPoultry,
    smallPets: IconSmallPet,
    'small-pets': IconSmallPet,
    'small pet': IconSmallPet,
    medication: IconMedication,
};

// eslint-disable-next-line import/prefer-default-export
export { animalIconsMap };
